<!--上传文件或图片组件-->
<template>
    <div>
        <el-upload action="#" list-type="picture-card" :http-request="jsonFileSubmit" :on-error="notifyError" :on-success="jsonFileSuccess"
                   :file-list="jsonFile">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
                <el-image class="el-upload-list__item-thumbnail" :src="file.viewIcon" fit="fill" alt="" v-if="file.status=='success'"></el-image>
                <el-progress v-if="jsonFileProgressParam.visible&&file.status!='success'" type="circle" :width="90"
                             :percentage="jsonFileProgressParam.percentage"></el-progress>
                <div class="el-upload-list__item-name" v-if="file.status=='success'">
                    {{file.fileName }}
                </div>
                <div class="el-upload-list__item-size" v-if="file.status=='success'">
                    {{file.size | sizeFilter}}
                </div>
                <span class="el-upload-list__item-actions" v-if="!jsonFileProgressParam.visible">
          <el-tooltip class="item" effect="dark" :content="`下载:${file.fileName}`" placement="top-start">
            <span class="el-upload-list__item-delete" @click="downloadFile(file.serverPath,file.fileName)">
              <i class="el-icon-download"></i>
            </span>
          </el-tooltip>
          <span class="el-upload-list__item-delete" @click="jsonFileRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
            </div>
        </el-upload>
    </div>
</template>
<script>
    import { postFile } from '@/api/utils/UploadFileOrImg.js'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
    export default {
        props: {
            inputArray: { type: Array, require: true }, //绑定的值
            label: { type: String, require: true }, //标题
            childDir: { type: String, require: true }, //创建的目录
        },
        data () {
            return {
                jsonFile: this.inputArray,
                /*json jsonFile上传的进度条状态 */
                jsonFileProgressParam: {
                    visible: false,
                    percentage: 0
                },
            };
        },
        methods: {
            /*特殊事件-出错跟踪 */
            notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },
            notifyError (error) { this.$notify.error({ title: '错误', message: error }); },
            downloadFile (downUrl, downName) { //下载文件
                let a = document.createElement('a')
                a.href = downUrl + '?downToken=' + this.$store.getters.getToken;
                a.download = downName;
                a.target = '_blank'//在新窗口打开
                a.click();
            },

            /*-------------------------------------------------json jsonFile上传 start-----------------------------------------------*/
            jsonFileSuccess () { //成功后清除控制条
                this.jsonFileProgressParam.visible = false; //将进度条清空
                this.jsonFileProgressParam.percentage = 0;//将进度条百分比置0
            },
            jsonFileRemove (file) {
                let index = this.jsonFile.indexOf(file);//查出文件在哪
                this.jsonFile.splice(index, 1);  // 从数组中添加 / 删除项目，然后返回被删除的项目
                this.$emit('success', this.jsonFile);
            },
            jsonFileSubmit (param) {
                const jsonFileData = new FormData()
                jsonFileData.append('file', param.file);
                jsonFileData.append('childFile', this.childDir);
                postFile(jsonFileData, {
                    onUploadProgress: (progressEvent) => {
                    this.jsonFileProgressParam.visible = true;
                let percent =
                        ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                this.jsonFileProgressParam.percentage = percent;
            }
            }).then(response => {
                    if (response.data.success) {
                    const jsonData = response.data.data;
                    jsonData.uid = param.file.uid; //没有UID更新时会跳动画
                    this.jsonFile.push(jsonData);//插到最后面
                    this.$emit('success', this.jsonFile);
                    param.onSuccess();  // 将进度条清空
                }
            else {
                    param.onError(response.data.msg);
                }
            })
            },
            /*-------------------------------------------------json jsonFile上传 end----------------------------------------------*/
        },
        watch: {
            inputArray: function () {
                this.jsonFile = this.inputArray;
            }
        },
        filters: {
            sizeFilter (value) {
                if (value) {
                    if (parseFloat(value / 1024).toFixed(2) > 512) {
                        return parseFloat(value / 1024 / 1023).toFixed(2) + 'M'
                    }
                    else {
                        return parseFloat(value / 1024).toFixed(2) + 'K'
                    }
                }
                else {
                    return "未知大小";
                }
            }
        },
    }
</script>
