import { httpFile } from '@/utils/request'
export const postFile = (fileData, config) => {
    return httpFile.post('/upload/file', fileData, config)
}
export const postImg = (fileData, config) => {
    return httpFile.post('/upload/img', fileData, config)
}
export const postCkFile = (fileData) => {
    return httpFile.post('/upload/ckFile', fileData)
}
export const postCkImg = (fileData) => {
    return httpFile.post('/upload/ckImg', fileData)
}

