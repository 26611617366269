import { httpLogin, httpJson } from '@/utils/request'
//定义一个Links接口,数据表接口
export const login = (formData) => {
    return httpLogin.post('/loginAction', formData)
}
export const getValidateCode = () => {
    return httpJson.get('/validateCode')
}

export const getSetting = () => {
    return httpJson.get('/settingInfo')
}
