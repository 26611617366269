import Vue from 'vue'
import VueRouter from 'vue-router'
import MainInfo from '../views/MainInfo.vue'
import HomeIndex from '../views/HomeIndex.vue'
import DocInfo from '@/views/wbkj/DocInfo.vue'
import DocInfoWx from '@/views/wbkj/DocInfoWx.vue'
import Downloads from '@/views/wbkj/DownloadsSet.vue'
import Menus from '@/views/wbkj/MenusSet.vue'
import Departs from '@/views/wbkj/DepartsSet.vue'
import Links from '@/views/wbkj/LinksSet.vue'
import Images from '@/views/wbkj/ImagesSet.vue'
import UserLogin from '@/views/UserLogin.vue'
import DocPanel from '@/views/wbkj/DocPanelSet.vue'
import CusPanel from '@/views/wbkj/CusPanelSet.vue'
import FloatWin from '@/views/wbkj/FloatWinSet.vue'
import Leave from '@/views/wbkj/LeaveSet.vue'
import Role from '@/views/wbkj/RoleSet.vue'
import IpPermit from '@/views/wbkj/IpPermitSet.vue'
import Admin from '@/views/wbkj/AdminSet.vue' //后台帐号管理
import Users from '@/views/wbkj/UsersSet.vue' //留言用户管理
import Member from '@/views/wbkj/MemberSet.vue' //值班人员管理
import Onduty from '@/views/wbkj/OndutySet.vue' //值班计划
import UserInfo from '@/views/wbkj/UserInfo.vue' //值班计划
import SettingInfo from '@/views/wbkj/SettingInfo.vue'

Vue.use(VueRouter)
//2.将路由组用引入到路由中去,说白了就是路径和容器对应
const routes = [

  {
    path: '/login', name: 'login', component: UserLogin, //登录页
  },
  {
    path: '/',
    component: MainInfo, //主容器
    redirect: '/home',
    children: [
      { path: '/home', component: HomeIndex },//首页
      { path: '/leave', component: Leave }, //留言管理
      { path: '/role', component: Role }, //角色管理
      { path: '/floatWin', component: FloatWin }, //弹飘窗管理
      { path: '/docInfo', name: 'docInfo', component: DocInfo },
      { path: '/docInfoWx', name: 'docInfoWx', component: DocInfoWx },
      { path: '/downloads', name: 'downloads', component: Downloads },
      { path: '/menus', name: 'menus', component: Menus },
      { path: '/departs', name: 'departs', component: Departs },
      { path: '/links', name: 'links', component: Links },
      { path: '/images', name: 'images', component: Images },
      { path: '/docPanel', name: 'docPanel', component: DocPanel },
      { path: '/cusPanel', name: 'cusPanel', component: CusPanel },
      { path: '/ipPermit', name: 'ipPermit', component: IpPermit },
      { path: '/admin', name: 'admin', component: Admin },
      { path: '/user', name: 'user', component: Users },
      { path: '/member', name: 'member', component: Member },
      { path: '/onduty', name: 'onduty', component: Onduty },
      { path: '/userInfo', name: 'userInfo', component: UserInfo },
      { path: '/settingInfo', name: 'settingInfo', component: SettingInfo },
    ]
  }

]
// 3. 创建 router 实例，然后传 `routes` 配置,就是将router放到vueRooter中去,
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
// 4.把这个暴露出来,其它js才可以去使用它
export default router