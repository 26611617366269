<!--  -->
<template>
  <textarea class="ckeditor" id="ckeditorContent" name="ckeditorContent" rows="20"></textarea>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  //name: "DocEditor",
  props: ['editHtml'],
  data () {
    //这里存放数据
    return {
      ckeditor: null,
      id: parseInt(Math.random() * 1000).toString()

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {

    editHtml: function () {
      if (this.editHtml != this.ckeditor.getData()) {
        this.ckeditor.setData(this.editHtml);
        this.$emit('input', this.editHtml);//传入父组件
      }

    }


  },
  //方法集合
  methods: {
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // computed () {
  //   const iframe = document.querySelector('#editorIframe')
  //   // 处理兼容行问题
  //   if (iframe.attachEvent) {
  //     iframe.attachEvent('onload', function () {
  //       console.log('iframe已加载完毕')
  //       const iframeWin = this.$refs.editorIframe.contentWindow;
  //       iframeWin.CKEDITOR.instances.editor1.setData("luonianqing1");
  //     })
  //   } else {
  //     iframe.onload = function () {
  //       console.log('iframe已加载完毕')

  //     }
  //   }

  // },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    const nowHeight = document.documentElement.clientHeight; //实时高度
    const self = this;
    self.ckeditor = window.CKEDITOR.replace('ckeditorContent', { height: nowHeight * 0.6 });
    self.ckeditor.setData(self.editHtml);
    self.ckeditor.on('change', function () {
      //console.log(self.ckeditor.getData());
      self.$emit('input', self.ckeditor.getData());//传入父组件
    });
    // self.ckeditor.on('instanceReady',
    //   function (event) {
    //     event.editor.getCommand('maximize').exec();

    //   }
    // )
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy: function () {
    this.ckeditor.destroy();
  },
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>