import { httpJson } from '@/utils/request'
//定义一个Cer取得接口,数据表接口

export const getGroupCount = (param) => {
    return httpJson.get('/sys/groupCount', { 'params': param })
}
export const getGroups = () => {

    return httpJson.get('/sys/groups')
}




