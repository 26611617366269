<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="18">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
        </el-button-group>
      </el-col>
      <el-col :span="6">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" @change="tableChangeSearchKey">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>

    <el-table ref="ipPermitTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="permitNote" label="规则说明" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.permitNote)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="起始IP" show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.ip11}}.{{scope.row.ip12}}.{{scope.row.ip13}}.{{scope.row.ip14}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="截止IP" show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.ip21}}.{{scope.row.ip22}}.{{scope.row.ip23}}.{{scope.row.ip24}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="是否允许" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-if="scope.row.permit">
            <el-tag type="success" effect="dark">允许</el-tag>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-tag type="danger" effect="dark">禁止</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="groupName" label="规则作用文章分类" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.groupName)"></span>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="ipPermitFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small">
        <!-- `prop` 为绑定的规则 -->
        <el-form-item label="规则名称" prop="permitNote">
          <el-input v-model="formData.permitNote" placeholder="请输入规则说明"></el-input>
        </el-form-item>

        <el-form-item label="起始IP">
          <el-input-number v-model="formData.ip11" prop="ip11" controls-position="right" :min="0" :max="255" placeholder="请输入1-255"></el-input-number>.
          <el-input-number v-model="formData.ip12" prop="ip12" controls-position="right" :min="0" :max="255" placeholder="请输入0-255"></el-input-number>.
          <el-input-number v-model="formData.ip13" prop="ip13" controls-position="right" :min="0" :max="255" placeholder="请输入0-255"></el-input-number>.
          <el-input-number v-model="formData.ip14" prop="ip14" controls-position="right" :min="0" :max="255" placeholder="请输入0-255"></el-input-number>
        </el-form-item>
        <el-form-item label="截止IP">
          <el-input-number v-model="formData.ip21" controls-position="right" :min="0" :max="2147483647" placeholder="请输入1-255"></el-input-number>.
          <el-input-number v-model="formData.ip22" controls-position="right" :min="0" :max="2147483647" placeholder="请输入0-255"></el-input-number>.
          <el-input-number v-model="formData.ip23" controls-position="right" :min="0" :max="2147483647" placeholder="请输入0-255"></el-input-number>.
          <el-input-number v-model="formData.ip24" controls-position="right" :min="0" :max="2147483647" placeholder="请输入0-255"></el-input-number>
        </el-form-item>
        <el-form-item label="是否允许" prop="permit">
          <el-checkbox v-model="formData.permit">允许通过</el-checkbox>
        </el-form-item>
        <el-form-item label="对应分类" prop="groupId">
          <el-select v-model="formData.groupId" placeholder="请选择" @change="changeSelectGroup">
            <el-option v-for="item in this.docPanelGroup" :key="item.id" :label="item.borderName" :value=item.id :disabled="!item.isPublic"></el-option>
          </el-select>
        </el-form-item>
        <el-descriptions title="注意事项" :column=formDialogParam.cloumnNum>
          <el-descriptions-item label="1">开启IP限制后,必须开启[允许规则],否则没有允许都视为禁止;</el-descriptions-item>
          <el-descriptions-item label="2">开多个禁止规则和仅一个禁止规则是一样的效果,因为不存在允许,所以都禁止;</el-descriptions-item>
          <el-descriptions-item label="3">如果规则相互包含,禁止规则是允许规则内的禁止,即禁止大于一切;</el-descriptions-item>
          <el-descriptions-item label="4">IP限制必须[分类]和[文章模块]同时设定才生效(例如 新闻与板块->新闻动态);</el-descriptions-item>
        </el-descriptions>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="permitNote" label="选定的规则">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if=" formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import { getTableData, getDocPanelGroup, doNew, doModify, doDelete, } from '@/api/wbkj/IpPermit'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': 'IP限制'

      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      docPanelGroup: [], //下拉选择的数组
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
        cloumnNum: 1
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        permitNote: '',
        ip11: 0,
        ip12: 0,
        ip13: 0,
        ip14: 0,
        ip21: 0,
        ip22: 0,
        ip23: 0,
        ip24: 0,
        permit: false,
        groupId: '',
        groupName: '',

      },
      formDialogRules:
      {
        permitNote: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        ip11: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        ip12: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        ip13: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        ip14: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        ip21: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        ip22: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        ip23: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        ip24: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        permit: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        groupId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        groupName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      }
    }
  },
  methods: {
    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },

    /*特殊事件-下载文件 */
    downloadFile (downUrl, downName) { //下载文件
      let a = document.createElement('a')
      a.href = downUrl;
      a.download = downName;
      a.target = '_blank'//在新窗口打开
      a.click();
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    changeSelectGroup (selectId) {
      const thisLabel = this.docPanelGroup.find(item => item.id === selectId).borderName
      this.formData.groupName = thisLabel;
    },

    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    /*表单(form)-发布示例 */
    /*formDataPublic () {
        if (this.tableMultipleSelection.length < 1) {
            this.notifyInfo("您必须最少选择一条记录,才能发布");
        }
        else {
            this.formChooseDialogParam.title = "将选定的记录,发布/取消发布"
            this.formChooseDialogParam.formChooseAction = 'public';
            this.formChooseDialogParam.action1 = true;
            this.formChooseDialogParam.action1View = '取消发布';
            this.formChooseDialogParam.action2 = true;
            this.formChooseDialogParam.action2View = '发 布';
            this.formChooseDialogParam.visible = true;
        }
    },*/
    formDataValidate () {
      let result = true;
      this.$refs.ipPermitFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      //const postData = JSON.parse(JSON.stringify(this.formData));
      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(this.formData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(this.formData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.ipPermitTableRef.clearSelection(); //清除选中
      this.$refs.ipPermitTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },

  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
    getDocPanelGroup().then((jsonData) => {
      this.docPanelGroup = jsonData.data.data;
    })
  }
}
</script>
