import { httpForm, httpJson, httpHtml } from '@/utils/request'
//定义一个接口,数据表接口
export const getTableData = (tableParam) => {
  return httpJson.get('/documents/docList', { 'params': tableParam })
}
export const getEditorHtml = (param) => {
  return httpHtml.get(param.fileUrl)
}
export const getInfo = (param) => {
  return httpJson.get('/documents/info', { 'params': param })
}
export const getAllowTransPanel = () => {
  return httpJson.get('/documents/allowTransPanel')
}
export const doNew = (formData) => {
  return httpForm.post('/documents/new', formData)
}
export const doModify = (formData) => {
  return httpForm.post('/documents/modify', formData)
}
export const doDelete = (formData) => {
  return httpForm.post('/documents/delete', formData)
}
export const doPublic = (formData) => {
  return httpForm.post('/documents/public', formData)
}
export const doPubWx = (formData) => {
  return httpForm.post('/documents/pubWx', formData)
}
export const doPubTop = (formData) => {
  return httpForm.post('/documents/pubTop', formData)
}
export const doTrans = (formData) => {
  return httpForm.post('/documents/trans', formData)
}
export const doOpen = (formData) => {
  return httpForm.post('/documents/doOpen', formData)
}
export const doCheck = (param) => {
  return httpForm.post('/documents/check', { 'params': param })
}
export const doAttachFile = (param) => {
  return httpForm.post('/documents/attach', { 'params': param })
}

