<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="18">
        <el-button-group>
          <el-button @click="formDataNewRoot" type="success" icon="el-icon-folder-add">根分类</el-button>
          <el-button @click="formDataNew(true)" type="primary" icon="el-icon-folder-add">分类</el-button>
          <el-button @click="formDataNew(false)" type="primary" icon="el-icon-plus">节点</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
          <el-button @click="formDataLock" type="warning">锁定/解锁</el-button>
        </el-button-group>
      </el-col>
      <el-col :span="6">
      </el-col>
    </el-row>

    <el-table ref="menusTableRef" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="treeTableData" tooltip-effect="dark"
      row-key="id" style="width: 100%" :highlight-current-row="true" @selection-change="treeTableSelectionChange" @row-click="treeTableRowClick"
      :load="treeTableLoadNode" lazy :tree-props="{children: 'children', hasChildren: 'isNode'}" :indent="20">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" prop="menuName" label="名称" show-overflow-tooltip>
        <template slot-scope="scope">
          <i v-if="scope.row.isNode" class="el-icon-folder-opened" style="color:#50bfff;"></i>
          {{scope.row.menuName}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="viewName" label="显示名称" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" prop="extParam" label="扩展属性" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-for="(val,key,i) in scope.row.extParam" :key="i">
            <el-button slot="reference" size="small" type="success" plain>{{key}}:{{val}}</el-button>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="hrefValue" label="链接(可以是文件)" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" prop="iconHref" label="菜单图标名称" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" prop="sort" label="排名" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" prop="id" label="ID" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="状态" width="120">
        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-if="scope.row.isLock">
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="已锁定" placement="top-start">
                <i class="el-icon-lock" style="font-size:24px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!--编辑的表单-->

    <el-dialog class="customFileListCss" :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width"
      :top="formDialogParam.top" :close-on-click-modal='false'>
      <el-form ref="menusFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small">
        <!-- `prop` 为绑定的规则 -->
        <el-form-item label="名称" prop="menuName">
          <el-input v-model="formData.menuName" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="显示名称" prop="viewName">
          <el-input v-model="formData.viewName" placeholder="请输入显示名称"></el-input>
        </el-form-item>
        <el-form-item label="图标名称" prop="iconHref">
          <el-input v-model="formData.iconHref" placeholder="请输入图标名称或链接"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="hrefValue">
          <el-input v-model="formData.hrefValue" placeholder="请输入链接(可以是文件)"></el-input>
        </el-form-item>
        <el-form-item label="扩展属性" prop="extParam">
          <el-tag :key="i" v-for="(val,key,i) in formData.extParam" closable :disable-transitions=" false" @close="extParamDelete(key)"
            style="margin-right: 10px;">
            {{key}}:{{val}}
          </el-tag>
          <el-button size="small" type="success" @click="extParamNew()">+</el-button>
        </el-form-item>
        <el-form-item label="排名" prop="sort">
          <el-input-number v-model="formData.sort" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00" placeholder="请输入排名">
          </el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="extParamDialogParam.title" :visible.sync="extParamDialogParam.visible" width="20%" :close-on-click-modal='false'>
      <el-form :model="extParamData" label-width="80px" size="small">
        <el-form-item label="键名(key)">
          <el-input v-model="extParamData.key" placeholder="请输入键名(key)"></el-input>
        </el-form-item>
        <el-form-item label="值(value)">
          <el-input v-model="extParamData.value" placeholder="请输入值(value)"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="extParamDialogParam.visible = false">取 消</el-button>
        <el-button @click="extParamPush()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="treeTableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="menuName" label="名称"></el-table-column>
        <el-table-column prop="viewName" label="显示名称"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-col :span="10">
          <el-input v-if="formChooseDialogParam.lockInputVisible" placeholder="锁定/解锁密码" v-model="formChooseDialogParam.lockPassValue" show-password>
          </el-input>
        </el-col>
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import { findDataBy, doNew, doModify, doDelete, doLock } from '@/api/wbkj/Menus'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '导航菜单设置'
      },
      /*表格参数 */
      treeTableParam: {
        parentId: 0,
      },//表格的传参
      /*表格数据 */
      treeTableData: [],
      /*表格选中的数据 */
      treeTableMultipleSelection: [], //多选的数据

      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '40%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: '',
        lockInputVisible: false,
        lockPassValue: '',
      },
      /*扩展属性 */
      extParamDialogParam: { //权限弹出窗口的显示状态
        visible: false,
        title: '新建属性',
        width: '20%',
        top: '15vh',
      },
      extParamRawData: {
        key: '',
        value: '',
      },
      extParamData: {
        key: '',
        value: '',
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        id: 0,
        menuName: '',
        iconHref: '',
        viewName: '',
        hrefValue: '',
        extParam: {},
        sort: 0.00,
        parentId: 0,
        isNode: false,
        isLock: false,
        lockPass: '',


      },
      formDialogRules:
      {
        menuName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        viewName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        hrefValue: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        sort: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        parentId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isNode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isLock: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        lockPass: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      }
    }
  },
  methods: {
    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },

    /*特殊事件-下载文件 */
    downloadFile (downUrl, downName) { //下载文件
      let a = document.createElement('a')
      a.href = downUrl;
      a.download = downName;
      a.target = '_blank'//在新窗口打开
      a.click();
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNewRoot () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formData.parentId = 0;
      this.formData.isNode = true;
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /**
     * 扩展属性新建
     */
    extParamNew () {
      this.extParamData = JSON.parse(JSON.stringify(this.extParamRawData))//重置
      this.extParamDialogParam.visible = true;
    },
    /**
     * 权限新建
     */
    extParamPush () {

      this.$set(this.formData.extParam, this.extParamData.key, this.extParamData.value);
      this.extParamDialogParam.visible = false;//不显示
    },
    extParamDelete (key) {
      this.$delete(this.formData.extParam, key);
    },
    formDataNew (create_isNode) {
      console.log(this.formData.id);
      if (this.formData.id == 0) {
        this.notifyInfo("您必须最少选择一条记录,才能新建");
        return;
      }
      const isNode = this.formData.isNode;//得到原来选中的结点,如果是结点,那么在新建结点就是结点下的数据,如果不是结点,那么建在该结点的同级
      const id = this.formData.id;
      const parentId = this.formData.parentId;
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      if (isNode) {//表示选中的是结点,那么新建的时候,该新建的对象的父结点就是原数据的Id
        this.formData.parentId = id;
      } else {
        this.formData.parentId = parentId;
      }
      this.formData.isNode = create_isNode;
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.treeTableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.treeTableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.treeTableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(结点必须清空才能删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.lockInputVisible = false;
        this.formChooseDialogParam.lockPassValue = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataLock () {
      if (this.treeTableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能锁定/解锁");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要锁定/解锁选定的记录"
        this.formChooseDialogParam.formChooseAction = 'doLock';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '解 锁';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '锁 定';
        this.formChooseDialogParam.lockInputVisible = true;
        this.formChooseDialogParam.lockPassValue = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    /*表单(form)-发布示例 */
    /*formDataPublic () {
        if (this.treeTableMultipleSelection.length < 1) {
            this.notifyInfo("您必须最少选择一条记录,才能发布");
        }
        else {
            this.formChooseDialogParam.title = "将选定的记录,发布/取消发布"
            this.formChooseDialogParam.formChooseAction = 'public';
            this.formChooseDialogParam.action1 = true;
            this.formChooseDialogParam.action1View = '取消发布';
            this.formChooseDialogParam.action2 = true;
            this.formChooseDialogParam.action2View = '发 布';
            this.formChooseDialogParam.visible = true;
        }
    },*/
    formDataValidate () {
      let result = true;
      this.$refs.menusFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      //const postData = JSON.parse(JSON.stringify(this.formData));
      //postData.classId = this.treeTableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      this.formData.extParam = JSON.stringify(this.formData.extParam);//由于MysqlJson的原因
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(this.formData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            if (this.formData.parentId == 0) //如果父结点的ID是0,需要重新Load
            {
              this.treeTableDateInit();//重新初使化
            }
            else {
              this.treeTableRefreshNode(this.formData.parentId);//新建刷新父结点,这里也不一定,
            }
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(this.formData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            if (this.formData.parentId == 0) //如果父结点的ID是0,需要重新Load
            {
              this.treeTableDateInit();//重新初使化
            }
            else {
              this.treeTableRefreshNode(this.formData.parentId);//新建刷新父结点,这里也不一定,
            }
          }
          else {
            this.notifyWarning(response.data.msg);
            this.treeTableRefreshNode();
          }
        })
      }
    },
    formChooseSubmit (isTrueOrFalse) {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.treeTableMultipleSelection.length;
        let canId = [];
        let parentIds = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.treeTableMultipleSelection[i].isPublic) {
            canId.push(this.treeTableMultipleSelection[i].id);
            console.log(parentIds.indexOf(this.treeTableMultipleSelection[i].parentId));
            if (parentIds.indexOf(this.treeTableMultipleSelection[i].parentId) == -1) {
              parentIds.push(this.treeTableMultipleSelection[i].parentId); //将他们的父Id保存下来,当然是要去重,如果父Id的长度是1,就表示全部相同,那么就只刷父级,否则刷全部
            }
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.$refs.menusTableRef.clearSelection(); //清除选中
            this.notifySuccess(response.data.msg);
            for (let i = 0; i < parentIds.length; i++) {
              if (parentIds[i] == 0) {//如果是0
                this.treeTableDateInit();//刷最上层
              }
              else {
                this.treeTableRefreshNode(parentIds[i]);//刷结点
              }
            }
            this.notifySuccess(response.data.msg);
          }
          else {
            this.notifyWarning(response.data.msg);
            this.treeTableRefreshNode();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'doLock') {
        if (this.formChooseDialogParam.lockPassValue == '' || this.formChooseDialogParam.lockPassValue.length < 6) {
          this.notifyWarning("密码信息不能为空,必须为6-8位");
          return;
        }
        else {
          this.formChooseDialogParam.visible = false;
        }
        const selectNum = this.treeTableMultipleSelection.length;
        let canId = [];
        let parentIds = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.treeTableMultipleSelection[i].id);
          if (parentIds.indexOf(this.treeTableMultipleSelection[i].parentId) == -1) {
            parentIds.push(this.treeTableMultipleSelection[i].parentId); //将他们的父Id保存下来,当然是要去重,如果父Id的长度是1,就表示全部相同,那么就只刷父级,否则刷全部
          }
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('isLock', isTrueOrFalse);
        data.append('lockPass', this.formChooseDialogParam.lockPassValue)
        doLock(data).then(response => {
          if (response.data.success) {
            this.$refs.menusTableRef.clearSelection(); //清除选中
            this.notifySuccess(response.data.msg);
            for (let i = 0; i < parentIds.length; i++) {
              if (parentIds[i] == 0) {//如果是0
                this.treeTableDateInit();//刷最上层
              }
              else {
                this.treeTableRefreshNode(parentIds[i]);//刷结点
              }
            }
            this.notifySuccess(response.data.msg);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    treeTableSelectionChange (val) {
      this.treeTableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    treeTableRowClick (row) {
      this.formData = row;
      console.log(row)
      this.$refs.menusTableRef.clearSelection(); //清除选中
      this.$refs.menusTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-数据初使化 */
    treeTableDateInit (parentId) {
      this.treeTableParam.parentId = parentId;
      findDataBy(this.treeTableParam).then((response) => {
        if (response.data.success) {
          this.treeTableData = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    //刷新点事件事件,用来展开和开始的时候进行调用
    treeTableRefreshNode (parentId) {

      this.treeTableParam.parentId = parentId;
      findDataBy(this.treeTableParam).then((response) => {
        if (response.data.success) {
          this.$set(this.$refs.menusTableRef.store.states.lazyTreeNodeMap, parentId, response.data.data);
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    //load事件,展开事件调用此方法,当展开时记录当前的结点的resolve,利用Hash的方法记录结点ID,以便新建修改,删除时,刷新父结点
    treeTableLoadNode (tree, treeNode, resolve) {
      // 请求api接口获取数据
      this.treeTableParam.parentId = tree.id;
      findDataBy(this.treeTableParam).then((response) => {
        if (response.data.success) {
          resolve(response.data.data);
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })

    },
  },
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.treeTableDateInit(0);
  }
}
</script>
