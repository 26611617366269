<template>
  <div id="loginLayout">
    <div class="login-form-wrap">
      <div class="login-title">{{settingData.siteTitle}}</div>
      <div>
        <el-form :model="loginData" :rules="rules" ref="ruleForm" label-width="80px" class="loginDataCss">
          <h3>{{settingData.siteSub}}</h3>
          <el-form-item label="账 号" prop="loginName">
            <el-input v-model="loginData.loginName" prefix-icon="el-icon-user" placeholder="请输入帐号"></el-input>
          </el-form-item>
          <el-form-item label="密 码" prop="loginPass">
            <el-input v-model="loginData.loginPass" prefix-icon="el-icon-lock" placeholder="请输入密码" type="password"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="checkCode">
            <img />
            <el-input v-model="loginData.checkCode" placeholder="请输入验证码">
              <template slot="prepend"><img :src="'data:image/Jpeg;base64,'+this.validateImg" style="height:33px" @click="refreshCode"></template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">登 录</el-button>
            <el-button @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="login-copy">
        Powered By Weber.Tech<br>
        @2012 - 2023
      </div>
    </div>
  </div>
</template>
<style  lang="less"  scoped>
.loginDataCss {
  width: 400px;
  border: 0px solid #f3f3f3;
  padding: 20px;
  margin: 0 auto;
  height: 280px;
  background-color: #666666;
  h3 {
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
  }
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.login-form-wrap {
  position: relative;
  padding-top: 15vh;
  width: 700px;
  height: 85vh;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-top: 0;
  border-bottom: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
}
.login-form-wrap form {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.login-title,
.login-copy {
  font-weight: bold;
  font-family: "Microsoft Yahei";
  color: #fff;
  text-align: center;
  text-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.login-title {
  font-size: 30px;
  margin-bottom: 5vh;
}
.login-copy {
  position: absolute;
  bottom: 3vh;
  width: 100%;
  font-weight: 200;
  font-size: 12px;
  text-transform: uppercase;
}
</style>
<style scoped>
.loginDataCss >>> .el-form-item__label {
  color: #fff;
}
</style>
<script>
import { login, getValidateCode, getSetting } from '@/api/login'; //数据源
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    return {
      validateImg: '', //验证图片
      settingData: {},
      loginData: {
        loginName: '',
        loginPass: '',
        checkCode: '',
      },
      rules: {
        loginName: [{
          required: true,
          message: '请输入登录名',
          trigger: 'change'
        }],
        loginPass: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 12,
          message: '长度在 6 到 12 个字符',
          trigger: 'blur'
        }
        ],
        checkCode: {
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        }
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

    refreshCode () {
      getValidateCode().then(response => {
        if (response.data.success) {
          //console.log(response.data.validateImg)
          this.validateImg = response.data.data.validateImg;
          //console.log(response.data.data.token)
          this.$store.commit("setValidateToken", response.data.data.token);

        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    initSetting () {
      getSetting().then(response => {
        if (response.data.success) {
          this.settingData = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    submitForm (formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(login);
          login(this.loginData).then(response => {
            if (response.data.success) {
              this.notifySuccess(response.data.msg);
              this.$store.commit("setToken", response.data.data.token);
              this.$store.commit("setLoginUser", response.data.data);
              //that.$store.commit("setUserInfo", response.data.account);
              //sessionStorage.setItem("loginUser",)
              setTimeout(() => {
                // 此时要判断/login后面的参数redirect，若无参数，进入主页；
                this.$router.push("/home");
                // 若有参数则参数为未有权限的那个路由，跳转到那个路由
                // this.$router.push(***); -- 具体要自己在这实现
              }, 1000);

            }
            else {
              this.notifyWarning(response.data.msg);
              this.refreshCode();
            }
          })
        } else {
          console.log('error submit!!');
          this.refreshCode();
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },

    /*特殊事件-下载文件 */
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    let _html_bg_src = parseInt(Math.random() * 10)
    document.querySelector('#loginLayout').style = `
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background: url(/bgImg/${_html_bg_src}.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  `,
      this.refreshCode();
    this.initSetting();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>