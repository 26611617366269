import { httpForm, httpJson } from '@/utils/request'
//定义一个CusBorder接口,数据表接口
export const findDataBy = (tableParam) => {
    return httpJson.get('/cusPanel/findDataBy', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/cusPanel/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/cusPanel/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/cusPanel/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/cusPanel/delete', formData)
}

export const doLock = (formData) => {
    return httpForm.post('/cusPanel/lock', formData)
}


