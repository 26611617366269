import { httpForm, httpJson } from '@/utils/request'
//定义一个Leave接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/leave/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/leave/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/leave/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/leave/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/leave/delete', formData)
}

