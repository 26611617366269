export default {
  state: {
    validateToken: "", //记录验证码的Token,
    token: "", //记录Token,
    loginUser: {}//
  },
  getters: {
    getToken (state) {
      return state.token || localStorage.getItem("token") || "";
    },
    getValidateToken (state) {
      return state.validateToken || localStorage.getItem("validateToken") || "";
    },
    getLoginUser (state) {
      let userInfo = localStorage.getItem("loginUser");
      if (userInfo) {
        state.loginUser = JSON.parse(userInfo);
        return JSON.parse(userInfo)
      }
      else {
        return state.loginUser || {};
      }
    }

  },
  mutations: {
    // 修改token，并将token存入localStorage
    setToken (state, token) {
      state.token = token;
      localStorage.setItem('token', token);
      console.log('store、localstorage保存token成功！');
    },
    setLoginUser (state, loginUser) {
      state.loginUser = loginUser;
      localStorage.setItem('loginUser', JSON.stringify(loginUser));
    },
    // 修改token，并将token存入localStorage
    setValidateToken (state, validateToken) {
      state.validateToken = validateToken;
      localStorage.setItem('validateToken', validateToken);
    },
    delToken (state) {
      state.token = "";
      localStorage.removeItem("token");
    },
    delValidateToken (state) {
      state.validateToken = "";
      localStorage.removeItem("validateToken");
    },
    delLoginUser (state) {
      state.loginUser = {};
      localStorage.removeItem("loginUser");
    }
  }
}
/**
 * 登录状态的全局token
 */