<!--  -->
<template>
  <el-menu default-active="home" class="el-menu-vertical-demo" :collapse="isCollapse" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b"
    :unique-opened=true router>
    <h3 style="display:flex"><img :src='`/icon/logo2.png`' width="50px" />{{isCollapse?'':settingData.sysName}}</h3>
    <el-menu-item index="home" @click="clickHome()">
      <i class="el-icon-s-home"></i>
      <span slot="title">首页</span>
    </el-menu-item>
    <el-submenu v-for="item in menuData" :key="item.id" :index="item.viewName">
      <template slot="title">
        <i class="el-icon-folder"></i>
        <span slot="title">{{item.viewName}}</span>
      </template>
      <el-menu-item v-for="bodyItem in item.bodyItems" :key="`${item.id}-${bodyItem.id}`" :index="`${item.id}-${bodyItem.id}`" @click="clickMenuItem(bodyItem)">
        {{bodyItem.viewName}}
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>



<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-width: 200px;
  min-height: 400px;
}
.el-menu {
  height: 100vh;
  border: 0;
  width: auto;
  h3 {
    padding-left: 10px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
  }
  li {
    ul {
      height: 100%;
    }
  }
}
.el-menu-item {
  height: 36px;
  line-height: 36px;
}
</style>

<script>
import { getLeftMenus } from '@/api/wbkj/MainMenu'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { getSetting } from '@/api/wbkj/Setting';
export default {
  data () {
    return {
      settingData: {
        siteTitle: "昆明微布科技有限公司",
        siteSub: "CMS系统",
        sysName: '新闻管理后端',
        pathEnable: false,
        pathUrl: "http://www.luoui.com/"
      },
      menuData: []
    };
  },
  methods: {
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },
    initSetting () {
      getSetting().then(response => {
        if (response.data.success) {
          this.settingData = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    clickHome () {
      if (this.$route.path != "/home") {
        this.$router.push("/home");
      }

    },
    clickMenuItem (bodyItem) {
      if (!(this.$route.path == "/docInfo" && this.$route.query.id == bodyItem.id)) {
        this.$store.commit('setActiveDoc', { id: bodyItem.id, directName: bodyItem.viewName }); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
        this.$router.push({
          name: 'docInfo',
          //query: { id: bodyItem.id },
          params: {
            id: bodyItem.id,
            directName: bodyItem.viewName
          }
        });
      }

    },
    clickEvalMenuItem (evalItem) {
      if (!(this.$route.path == "/evaluating" && this.$route.query.evalType == evalItem.id)) {
        this.$store.commit('setActiveEval', { id: evalItem.id, directName: evalItem.viewName }); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
        this.$router.push({
          name: 'evaluating',
          params: {
            id: evalItem.id,
            directName: evalItem.viewName
          }
        });
      }

    }
  },
  mounted () {
    //取得全部角色
    getLeftMenus().then(response => {
      if (response.data.success) {
        this.menuData = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    this.initSetting();
  },
  computed: {
    isCollapse () {
      return this.$store.state.LeftMenuState.isCollapse;
    }
  }
}
</script>