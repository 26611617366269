import { httpForm } from '@/utils/request'
//定义一个Cer取得接口,数据表接口
/**
 * 修改用户密码
 * @param {} formData 
 * @returns 
 */
export const doModify = (formData) => {
    return httpForm.post('/admin/passwordModify', formData)
}




