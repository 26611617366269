import { httpJson } from '@/utils/request'
//定义一个Menus接口,数据表接口
export const getTopMenus = () => {
    return httpJson.get('/topMenus/list')
}

export const getLeftMenus = () => {
    return httpJson.get('/leftMenus/list')
}


